<template>
    <div class="question-container">
        <div class="question-content">
            <div class="question-title" v-if="questionList[pageNo-1].type>=0 && questionList[pageNo-1].title">
                <span>{{`[${questionList[pageNo-1].type === 0 ?'单选' : '多选' }]`}}</span> 
                {{questionList[pageNo-1].title}}
            </div>
            <div class="question-list" v-if="questionList[pageNo-1].type>=0  && questionList[pageNo-1].options">
                <!-- 单选 -->
                <template v-if="questionList[pageNo-1].type === 0">
                    <div class="radio-question">
                        <van-radio-group v-model="answerlist[pageNo-1].options" >
                            <van-cell-group>
                                <template v-for="(item, index) in questionList[pageNo-1].options" >
                                    <van-cell 
                                    class="question-item-content"
                                    :title="item.options" 
                                    clickable @click="answerlist[pageNo-1].options = item.id" 
                                    :key="index">
                                        <van-radio slot="right-icon" :name="item.id"  @click="radioChange"/> 
                                    </van-cell>
                                    <!-- 子选项 -->
                                    <div v-if="answerlist[pageNo-1].options == item.id && item.children.length > 0" class="options-children" :key="index">
                                        <div class="leadingWords">{{item.leadingWords}}</div>
                                        <!-- 单选 -->
                                        <template v-if="item.subtype == 0 ">
                                            <van-radio-group class="select-ui" v-model="answerlist[pageNo-1].rChild">
                                                <van-radio 
                                                v-for="(childrenItem, i) in item.children"
                                                :key="i"
                                                class="select-item" 
                                                :name="childrenItem.id" 
                                                icon-size="13px">
                                                 {{childrenItem.options}}
                                                </van-radio>
                                            </van-radio-group>
                                        </template>
                                        <!-- 多选 -->
                                        <template v-if="item.subtype == 1">
                                            <van-checkbox-group class="select-ui" v-model="answerlist[pageNo-1].rChildren">
                                                <van-checkbox
                                                v-for="(childrenItem, i) in item.children"
                                                :key="i"
                                                 class="select-item"  
                                                 :name="childrenItem.id" 
                                                 icon-size="13px"> 
                                                    {{childrenItem.options}}
                                                 </van-checkbox>
                                                
                                            </van-checkbox-group>
                                        </template>
                                    </div>
                                </template>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                </template>
                <!-- 多选 -->
                <template  v-if="questionList[pageNo-1].type === 1">
                    <div class="checkBox-question"> 
                        <van-checkbox-group v-model="answerlist[pageNo-1].options">
                            <van-cell-group>
                                 <template v-for="(item, index) in questionList[pageNo-1].options" >
                                    <van-cell
                                    class="question-item-content" 
                                    clickable
                                    :key="index"
                                    :title="item.options"
                                    @click="toggle(index)"
                                    >
                                    <van-checkbox
                                        :name="item.id"
                                        ref="checkboxes"
                                        slot="right-icon"
                                        @click="checkboxChange"
                                    />
                                    </van-cell> 
                                    <!-- 子选项 -->
                                    <div v-if="(answerlist[pageNo-1].options.indexOf(item.id) !== -1) && item.children.length > 0" class="options-children" :key="index">
                                        <div class="leadingWords">{{item.leadingWords}}</div>
                                        <!-- 单选 -->
                                        <template v-if="item.subtype == 0 ">
                                            <van-radio-group class="select-ui" v-model="answerlist[pageNo-1].children[index].options">
                                                <van-radio 
                                                v-for="(childrenItem, i) in item.children"
                                                :key="i"
                                                class="select-item" 
                                                :name="childrenItem.id" 
                                                icon-size="13px">
                                                 {{childrenItem.options}}
                                                </van-radio>
                                            </van-radio-group>
                                        </template>
                                        <!-- 多选 -->
                                        <template v-if="item.subtype == 1 ">
                                            <van-checkbox-group class="select-ui" v-model="answerlist[pageNo-1].children[index].options">
                                                <van-checkbox
                                                v-for="(childrenItem, i) in item.children"
                                                :key="i"
                                                 class="select-item"  
                                                 :name="childrenItem.id" 
                                                 icon-size="13px"> 
                                                    {{childrenItem.options}}
                                                 </van-checkbox>
                                            </van-checkbox-group>
                                        </template>
                                    </div>
                                 </template>
                            </van-cell-group>
                        </van-checkbox-group>
                    </div>
                </template>
            </div>
        </div>
        <div class="question-handle" style="background: #fff;font-size: 0;">
            <van-button type="default" :disabled="isPre || pageNo <= 1" @click="preOneClick()"  >上一题</van-button>
            <!-- <van-button v-if="pageNo - recordCount !== 0" class="btn-2" type="default" :disabled="isapply" @click="toMatch()">{{isEdit == 0 ? '先提交' : '更新完成'}}</van-button> -->
            <van-button v-show="status ==1" color="#0060C2" @click="nextOneClick()" :disabled="isNext || finish == 1" >下一题</van-button>
            <!-- <van-button v-if="status == -1" class="btn-finish" color="#0060C2" @click="toMatch()" :disabled="isapply">{{isEdit == 0 ? '提交（试验匹配）' : '更新完成'}}</van-button> -->
        </div>
    </div>
</template>
<script>
import { RadioGroup, Radio,  Cell, CellGroup, Checkbox, CheckboxGroup, Button, NumberKeyboard, Dialog, Toast } from 'vant';
import patientApi from '@api/patient/patientApi.js'
import {toPath} from '@/utils/common'
export default {
    components: {
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Button.name]: Button,
        
    },
    data() {
        return {
            isEdit: 0,
            questionList: [
                {type: 0, options: [],title: ''}
            ],
            // params:{
                pageNo: 1,
                illId: '',
                inquiryId: '',
            // },
            status: 1,
            answerlist: [],
            answerItem: {
                inquiryId: '',                  // 病患id
                titleId: '',                    // 问题id
                options: '',                    // 回答选项序列
            },
            isPre: false,
            isNext: false,
            isapply: false,
            finish: 0,
        }
    },
    created() {
        const { illId, inquiryId, isEdit } = this.$route.query
        Object.assign(this, {illId, inquiryId} )
        this.isEdit = isEdit || 0
        this.getQuestion({inquiryId})
    },
    methods:{
        checkboxChange() {
            const { pageNo } = this
            const { options:list } = this.questionList[pageNo-1]
            const { options } = this.answerlist[pageNo-1]
            list.forEach((item, i) => {
                const {id, subtype,  children } = item
                if(options.indexOf(id) === -1  && children.length > 0) {
                   this.answerlist[pageNo-1].children[i] = item.subtype === 1 ?  {options: []} :  {options: ''}
                }
            })
        },
        radioChange() {
            const { pageNo } = this
            const { options } = this.questionList[pageNo-1]
            Object.assign(this.answerlist[pageNo-1], {rChild: '', rChildren: []} )
        },
        // 提交及匹配
        // toMatch() {
        //     const { answerlist, pageNo, answerItem, inquiryId, isapply } = this
        //     const { type, options, titleId } = answerlist[pageNo-1]
        //     if(isapply) return
        //     Object.assign(answerItem, {
        //         titleId,
        //         inquiryId,
        //         options: type === 1 ? options.join(',') : options,
        //     })
        //     this.isapply = true
        //     // console.log('answerItem',answerItem)
        //     patientApi.submitAnswer({...answerItem}).then(res => {
        //         this.isapply = false
        //         // 跳转到试配主页
        //         // this.$router.push({
        //         //     path: '/patientMatchList',
        //         //     query: { inquiryId }
        //         // })
        //         toPath(this, {
        //             path: '/patientMatchList',
        //             query: { inquiryId }
        //         },`/pages/match/index/index?inquiryId=${inquiryId}`)
        //     }).catch(err => {
        //         this.isapply = false
        //     })
            
        // },
        // 返回上一题，回显上一题选中的问题
        preOneClick() {
            const { pageNo, answerlist, inquiryId } = this 
            // 返回上一页调用上一页接口 20200219
            const { titleId } = answerlist[pageNo-1]
            if(this.isPre) return
            this.isPre = true
            this.getPreQuestion({inquiryId, titleId }) // 告知后台返回上一页     

            // 判断当前页是否为最后一页
            // if(pageNo === answerlist.length) {
            //     this.pageNo--
            // } else {
            //     const answerItem = this.submitAnswer()
            //     if(this.isPre) return
            //     this.isPre = true
            //     this.getPreQuestion({...answerItem}) // 告知后台返回上一页    
            // }
            

        },
        // 下一题 并提交答案
        nextOneClick() {
            // console.log('answerlist',this.answerlist)
            const answerItem = this.submitAnswer()
            if(this.isNext) return
            this.isNext = true
            this.pageNo++
            this.getQuestion({...answerItem})
            

        },
        // 提交答案
        submitAnswer() {
            const { answerlist, pageNo, answerItem, inquiryId, isEdit, questionList } = this
            // console.log('submitAnswer',answerlist )
            const { options, titleId, rChild, rChildren,  children: checkObj } = answerlist[pageNo-1]
            const {type, options: list  } = questionList[pageNo-1]
            let answerStr = ''
            // console.log('submitAnswer',list )
            if(type == 0) {
                if(!options) return Toast('请回答当前问题')
                for(let item of list) {
                    const {id,  children } = item 
                    if(id == options && children.length > 0) {
                        if(!rChild && rChildren.length <= 0) {
                           return Toast('请回答当前问题')
                        }
                    }
                }
                answerStr = `${options},` 
                if(rChild) answerStr += `${rChild}`
                if(rChildren.length > 0) answerStr +=  rChildren.join(',')
            }
            if(type == 1) {
                let flag = true
                if(options.length <= 0) return Toast('请回答当前问题')
                answerStr = `${options.join(',')},` 
                list.forEach((item, i) => {
                    const {id, subtype,  children } = item
                    if(options.indexOf(id) > -1  && children.length > 0) {
                        if(checkObj[i].options == '' && checkObj[i].options.length <= 0) {
                           return flag = false
                        } else {
                          answerStr += subtype == 1 ? `${checkObj[i].options.join(',')},` : `${checkObj[i].options},`
                        }
                    }
                    // console.log('flag',flag)
                })
                if(!flag) return Toast('请回答当前问题')
            }
            Object.assign(answerItem, {
                titleId,
                inquiryId,
                options: answerStr.replace(/,$/g,'')
            })
            return answerItem
            
        },
        // 上一页
        getPreQuestion(options) {
             patientApi.getPreQuestion(options).then(res => {
                //  console.log('返回上一页')
                 this.pageNo--
                 this.isPre = false
                //  重制下一题答案及问题 20200219
                 this.questionList[this.pageNo] = {}
                 this.answerlist[this.pageNo] = {}
             }).catch(err => {
                 this.isPre = false
             })
        },
        // 下一页
        getQuestion(options) { // 修改用病患id获取问题
            const { questionList, answerlist, pageNo, illId,inquiryId } = this
            patientApi.getQuestion(options).then(res => {
                const {question, type, titleId, title, options, finish } = res.data
                this.isNext = false
                let children = {}
                // 问题已完成
                if(finish) {
                    this.finish = finish
                    Dialog.alert({
                        message: '全部问题已回答完毕',
                        confirmButtonText: '查看匹配项目'
                    }).then(() => {
                        toPath(this, {
                            path: '/patientMatchList',
                            query: { inquiryId }
                        },`/pages/match/index/index?inquiryId=${inquiryId}`, 'replace')
                    });
                }
                // 判断问题是否开启
                if(question == 0) {
                     Dialog.alert({
                        message: '抱歉，系统里没有设置该病种的问题，您可以直接查看该病种相关的项目。'
                    }).then(() => {
                        toPath(this, {
                            path: '/patientMatchList',
                            query: { inquiryId: this.inquiryId }
                            },
                            `/pages/match/index/index?inquiryId=${this.inquiryId}`, 'replace')
                    });
                } else { 
                    questionList[pageNo-1] = res.data
                    answerlist[pageNo-1] = {
                        type,
                        titleId,
                        options: type == 1 ? [] : ''
                    }
                    if(type == 1) {
                        options.forEach((item, i) => {
                            if(item.children.length > 0) {
                                 children[i] = item.subtype === 1 ? {options: []} : {options: ''}
                            }
                        });
                        answerlist[pageNo-1].children = children
                    }
                    if(type == 0) {
                        Object.assign(answerlist[pageNo-1], {rChild: '', rChildren: []} )
                    }
                    // console.log('questionList', questionList)
                    this.questionList = [...questionList]
                    this.answerlist = [...answerlist]
                }
                
                 
            }).catch(err => {
                this.isNext = false
                this.pageNo = this.pageNo == 1 ? 1 : this.pageNo - 1
            })
        },
        // 更新回答问题
        // modifyQuestion() {
        //     const { questionList, answerlist, pageNo, inquiryId } = this
        //     patientApi.modifyQuestion({ pageNo , inquiryId }).then(res => {
        //         const { recordCount, list, pageNo } = res.data
        //         this.isNext = false
        //         if(list && list.length > 0) {
        //             const {type, titleId, options, answer } = list[0]
        //             questionList[pageNo-1] = list[0]
        //             answerlist[pageNo-1] = {
        //                 type,
        //                 titleId,
        //                 options: type == 1 ? (answer ? answer.split(',').map(item => Number(item)) : []) : (answer ? Number(answer) : '')
        //             } 
        //             this.recordCount = recordCount
        //             // console.log('questionList', questionList)
        //             this.questionList = [...questionList]
        //             this.answerlist = [...answerlist]
        //             // console.log('answerlist', this.answerlist)
        //         }
        //     }).catch(err => {
        //         this.isNext = false
        //     })
        // },
        toggle(index) {
            this.$refs.checkboxes[index].toggle();
            // this.questionList[pageNo-1].options[index].ischeck = true
        }
    }
}
</script>

<style lang="less">
.question-container {
    min-height: 100vh;
    padding-bottom: 100px;
    background: #F7F7F7;
    .question-content {
        .question-title{
            padding: 15px;
            font-size:14px;
            color:rgba(130,130,132,1);
            line-height: 1.5;
        }
        .question-list {
            .question-item-content {
                font-size:16px;
                color:rgba(130,130,132,1);
                line-height: 1.5;
                padding: 15px;
                .van-cell__title {
                    margin-right: 10px;
                }
                &:not(:last-child)::after {
                    width: 92%;
                }
            }
        }
        .options-children {
            padding: 15px;
            font-size: 16px;
            .leadingWords {
                font-size: 14px;
                color: #ACABAD;
            }
            .select-ui {
                display: flex;
                flex-wrap: wrap;
                color: #828284;
                .select-item {
                    min-width: 30%;
                    margin: 18px 18px 0 0;
                    font-size: 16px;
                    .van-radio__label {
                        color: #828284;
                    }
                    .van-checkbox__label {
                         color: #828284;
                    }
                }
            }
        }
        
    }
    .question-handle {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99;
        button  {
            width: 50%;
            height: 49px;
            font-size:17px;
            color: #828284;
        }
        .btn-2 {
            font-weight:500;
            color: #313134;
        }
        .btn-finish {
            width: 66.6%;
        }
    }
}
</style>
